/*
 * @Description: 购物车状态模块
 * @Author: hai-27
 * @Date: 2020-02-21 18:40:41
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 20:38:55
 */
export default {
  state: {
    shoppingCart: [],
    // shoppingCart结构
  },
  getters: {
    getShoppingCart(state) {
      // 获取购物车状态
      return state.shoppingCart;
    },
    getTotalPrice(state) {
      let price = 0;
      state.shoppingCart.forEach((item) => {
        let p =
          item.inputModel != 1
            ? (
                (item.width * item.Length * item.sellingPrice * 1000) /
                1000
              ).toFixed(3)
            : ((item.width * item.sellingPrice * 1000) / 1000).toFixed(3);
        let p2 = 0;
        item.opionsList.forEach((ele) => {
          p2 += Number(ele.price);
        });
        price += Number(
          (Math.round((p + p2) * item.number * 100) / 100).toFixed(2)
        );
      });
      return (Math.round(price * 100) / 100).toFixed(2);
    },
    getShoppingCartNum(state) {
      let num = 0;
      state.shoppingCart.forEach((item) => {
        num += item.number;
      });
      return num;
    },
  },
  mutations: {
    addShoppingCart(state, data) {
      state.shoppingCart.push(data);
    },
    setShoppingCart(state, data) {
      // 设置购物车状态
      state.shoppingCart = data;
    },
    unshiftShoppingCart(state, data) {
      // 添加购物车
      // 用于在商品详情页点击添加购物车,后台添加成功后，更新vuex状态
      state.shoppingCart.unshift(data);
    },
    updateShoppingCartNum(state, data) {
      // 增加购物车商品数量
      const { number, index } = data;
      state.shoppingCart[index].number = number;
    },
    deleteShoppingCart(state, index) {
      // 根据购物车id删除购物车商品
      state.shoppingCart.splice(index, 1);
    },
    clearShoppingCart(state) {
      state.shoppingCart = [];
    },
  },
  actions: {
    addShoppingCart({ commit }, data) {
      commit("addShoppingCart", data);
    },
    setShoppingCart({ commit }, data) {
      commit("setShoppingCart", data);
    },
    unshiftShoppingCart({ commit }, data) {
      commit("unshiftShoppingCart", data);
    },
    updateShoppingCartNum({ commit }, data) {
      commit("updateShoppingCartNum", data);
    },
    addShoppingCartNum({ commit }, productID) {
      commit("addShoppingCartNum", productID);
    },
    deleteShoppingCart({ commit }, index) {
      commit("deleteShoppingCart", index);
    },
    checkAll({ commit }, data) {
      commit("checkAll", data);
    },
  },
};
