/*
 * @Description: 用户登录状态模块
 * @Author: hai-27
 * @Date: 2020-02-19 17:42:11
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-26 23:14:32
 */
import { getUserInfo } from "@/api/user.js";

export default {
  state: {
    user: "", // 登录的用户
    showLogin: true, // 用于控制是否显示登录组件
    iframeVisible: true,
    questionVisible: false,
    calculateModal: false,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getShowLogin(state) {
      return state.showLogin;
    },
    getIframeVisible(state) {
      return state.iframeVisible;
    },
    getQuestionVisible(state) {
      return state.questionVisible;
    },
    getCalculateModal(state) {
      return state.calculateModal;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setShowLogin(state, data) {
      state.showLogin = data;
    },
    showIframe(state, data) {
      state.iframeVisible = data;
    },
    showQuestion(state, data) {
      state.questionVisible = data;
    },
    showEstimation(state, data) {
      state.calculateModal = data;
    },
  },
  actions: {
    setUser({ commit }, data) {
      commit("setUser", data);
    },
    setShowLogin({ commit }, data) {
      commit("setShowLogin", data);
    },
    async getUserInfo({ commit }) {
      const res = await getUserInfo();
      if (res.code == 1) {
        commit("setUser", "");
        commit("setUser", res.data);
      } else {
        commit("setUser", "");
      }
    },
  },
};
