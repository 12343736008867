import request from "@/utils/request";
import Cookies from "js-cookie";

let language = Cookies.get("language") ? Cookies.get("language") : "en_GB";

import md5 from "js-md5";
// 用户登录
export function login(data) {
  return request({
    url: `user/login/${language}`,
    method: "post",
    data: {
      mailbox: data.mailbox,
      password: md5(
        "88*&6AKcsgskcsbdjhfvjSDFSDGjbhsdj%)(((jdhgsf!" + data.password
      ).toUpperCase(),
    },
  });
}
// 获取邮箱二维码
export function getMyCode(mailbox, type) {
  return request({
    url: `user/getCode/${language}`,
    method: "get",
    params: {
      mailbox: mailbox,
      type: type,
    },
  });
}

// 注册
export function register(data) {
  return request({
    url: `user/register/${language}`,
    method: "post",
    data: {
      mailbox: data.mailbox,
      password: md5(
        "88*&6AKcsgskcsbdjhfvjSDFSDGjbhsdj%)(((jdhgsf!" + data.password
      ).toUpperCase(),
      code: data.code,
      userName: data.userName,
    },
  });
}

// 获取用户详情
export function getUserInfo() {
  return request({
    url: `user/getUserInfo/${language}`,
    method: "get",
  });
}
// 修改用户信息
export function updateUserInfo(data) {
  return request({
    url: `user/updateUserInfo/${language}`,
    method: "post",
    data: {
      mailbox: data.mailbox,
      userName: data.userName,
      password: md5(
        "88*&6AKcsgskcsbdjhfvjSDFSDGjbhsdj%)(((jdhgsf!" + data.password
      ).toUpperCase(),
    },
  });
}

// 修改用户密码
export function updatePassword(data) {
  return request({
    url: `user/updatePassword/${language}`,
    method: "post",
    data: {
      mailbox: data.mailbox,
      code: data.code,
      password: md5(
        "88*&6AKcsgskcsbdjhfvjSDFSDGjbhsdj%)(((jdhgsf!" + data.password
      ).toUpperCase(),
    },
  });
}

// 获取用户订单列表
export function orderListForWeb(pageNo, pageSize) {
  return request({
    url: `order/orderListForWeb/${language}`,
    data: {
      pageNo,
      pageSize,
    },
    method: "get",
  });
}
// 修改用户地址
export function addressOperation(data) {
  return request({
    url: `user/addressOperation/${language}`,
    method: "post",
    data: {
      uaId: data.uaId,
      name: data.name,
      surname: data.surname,
      country: data.country,
      street: data.street,
      streetDetails: data.streetDetails,
      region: data.region,
      postalCode: data.postalCode,
      telephone: data.telephone,
      mailbox: data.mailbox,
    },
  });
}
